import React from "react";
import { Col, Row, Table, Tag, Typography } from "antd";
import { IProductDetails } from "api/requests";

const { Title } = Typography;

interface IItemsPerLocationProps {
  data: any;
}

const ItemsPerLocation: React.FC<IItemsPerLocationProps> = ({ data }) => {
  const columns = [
    {
      title: "Location ID",
      dataIndex: "idLocation",
      key: "idLocation",
      align: "center" as const,
    },
    {
      title: "Location Name",
      dataIndex: "locationName",
      key: "locationName",
      align: "center" as const,
    },
    {
      title: "Total Quantity",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      align: "center" as const,
      render: (quantity: number) => {
        if (quantity >= data.productIdealStock) {
          return <Tag color="green">{quantity} (Good)</Tag>;
        } else if (quantity < data.productLowStockAlert) {
          return <Tag color="red">{quantity} (Low)</Tag>;
        } else {
          return <Tag color="orange">{quantity} (Moderate)</Tag>;
        }
      },
    },
  ];

  return (
    <Col xs={24}>
      <Row>
        <Col xs={24}>
          <Table
            bordered
            dataSource={data.itemsPerLocation || []}
            columns={columns}
            rowKey="idLocation"
            pagination={false}
            style={{ marginTop: "16px" }}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default ItemsPerLocation;
