import { QueryClient } from "@tanstack/react-query";
import { Col, Dropdown, message, notification, Space } from "antd";
import {
  useInventoryServiceApprovePurchaseOrder,
  useInventoryServiceGetPurchaseOrderKey,
} from "api/queries";
import { IReparationCase, IReparationCaseDetails, ReparationCaseStatus } from "api/requests";
import { FC, useState } from "react";
// import CreatePaymentModal from "./CreatePaymentModal";
// import UpdateDeliveryStatusModal from "./UpdateDeliveryStatusModal";
// import CreateDeliveryNoteModal from "./CreateDeliveryNoteModal";

interface IRCViewHeaderOptionsProps {
  status: ReparationCaseStatus;
  queryClient: QueryClient;
  purchaseOrderId: number;
  data: IReparationCaseDetails;
}

const RCViewHeaderOptions: FC<IRCViewHeaderOptionsProps> = ({
  status,
  queryClient,
  purchaseOrderId,
  data,
}) => {
  const { mutate: approvePurchaseOrder, isPending: approvePending } =
    useInventoryServiceApprovePurchaseOrder({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetPurchaseOrderKey],
        });
        message.success("Purchase order approved successfully");
      },
      onError: (error: any) => {
        notification.error({
          message: "Failed to approve purchase order",
          description:
            error?.body?.message ||
            error?.response?.data?.message ||
            error?.message,
        });
      },
    });

  return (
    <Col xs={12}>
      <Space
        align="center"
        direction="horizontal"
        // size="middle"
        style={{ justifyContent: "end", width: "100%" }}
      >
        <Dropdown.Button
          menu={{
            items: [
              {
                key: "create-receipt-key",
                label: "Create Receipt",
                onClick: () => console.log("create receipt"),
              },
              {
                key: "duplicate-purchase-order-key",
                label: "Duplicate Purchase Order",
                onClick: () => console.log("duplicate purchase order"),
              },
            ],
          }}
          type="default"
        >
          Print
        </Dropdown.Button>
      </Space>
    </Col>
  );
};

export default RCViewHeaderOptions;
