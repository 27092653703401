// generated with @7nohe/openapi-react-query-codegen@1.4.0 

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AccountingService, AuthService, CustomerService, InventoryReportsService, InventoryService, ReparationService, SalesService, SettingsService, UsersService, VendorService, VendorsService } from "../requests/services.gen";
import { CreateCustomerInput, CreateExpenseCategoryInput, CreateExpenseInput, CreateProductInput, CreateServiceCategoryInput, CreateVendorInput, ICompanyDetailsInput, ICreateBankAccountInput, ICreateBrandInput, ICreateCurrencyInput, ICreateDeliveryNoteInput, ICreateInventoryTransactionInput, ICreateInvoiceInput, ICreateModelCategoryInput, ICreateOrUpdateReparationCaseReview, ICreateProductVariantInput, ICreateProductVariantTypeInput, ICreateProductVariantValueInput, ICreatePurchaseOrderInput, ICreatePurchaseOrderPaymentInput, ICreateReparationCaseItemInput, ICreateRoleInput, ICreateTaxInput, ICreateTransactionInput, ICreateUserInput, IUpdateBankAccountInput, IUpdateBrandInput, IUpdateCustomerInput, IUpdateLocationInput, IUpdateModelCategoryInput, IUpdateModelInput, IUpdateProductCategoryInput, IUpdateProductInput, IUpdatePurchaseOrderDeliveryStatusInput, IUpdateReparationCaseItemInput, IUpdateRoleInput, IUpdateTaxInput, IUpdateTransactionInput, IUpdateUserInput, IUpdateVendorInput } from "../requests/types.gen";
import * as Common from "./common";
export const useUsersServiceGetUsers = <TData = Common.UsersServiceGetUsersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersServiceGetUsersKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => UsersService.getUsers({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useUsersServiceGetUser = <TData = Common.UsersServiceGetUserDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersServiceGetUserKeyFn(queryKey), queryFn: () => UsersService.getUser() as TData, ...options });
export const useUsersServiceGetRoles = <TData = Common.UsersServiceGetRolesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersServiceGetRolesKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => UsersService.getRoles({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useUsersServiceGetRole = <TData = Common.UsersServiceGetRoleDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ roleId }: {
  roleId: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersServiceGetRoleKeyFn({ roleId }, queryKey), queryFn: () => UsersService.getRole({ roleId }) as TData, ...options });
export const useSettingsServiceGetProductVariantTypes = <TData = Common.SettingsServiceGetProductVariantTypesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSettingsServiceGetProductVariantTypesKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => SettingsService.getProductVariantTypes({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useSettingsServiceGetProductVariantType = <TData = Common.SettingsServiceGetProductVariantTypeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSettingsServiceGetProductVariantTypeKeyFn({ id }, queryKey), queryFn: () => SettingsService.getProductVariantType({ id }) as TData, ...options });
export const useSettingsServiceGetProductVariantValues = <TData = Common.SettingsServiceGetProductVariantValuesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSettingsServiceGetProductVariantValuesKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => SettingsService.getProductVariantValues({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useSettingsServiceGetProductVariantValue = <TData = Common.SettingsServiceGetProductVariantValueDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSettingsServiceGetProductVariantValueKeyFn({ id }, queryKey), queryFn: () => SettingsService.getProductVariantValue({ id }) as TData, ...options });
export const useSettingsServiceGetCompanyDetails = <TData = Common.SettingsServiceGetCompanyDetailsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSettingsServiceGetCompanyDetailsKeyFn(queryKey), queryFn: () => SettingsService.getCompanyDetails() as TData, ...options });
export const useVendorServiceGetAllVendors = <TData = Common.VendorServiceGetAllVendorsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseVendorServiceGetAllVendorsKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => VendorService.getAllVendors({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useVendorsServiceGetVendorById = <TData = Common.VendorsServiceGetVendorByIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ vendorId }: {
  vendorId: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseVendorsServiceGetVendorByIdKeyFn({ vendorId }, queryKey), queryFn: () => VendorsService.getVendorById({ vendorId }) as TData, ...options });
export const useVendorsServiceGetVendorPurchases = <TData = Common.VendorsServiceGetVendorPurchasesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort, vendorId }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
  vendorId: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseVendorsServiceGetVendorPurchasesKeyFn({ filters, pagination, searchQuery, sort, vendorId }, queryKey), queryFn: () => VendorsService.getVendorPurchases({ filters, pagination, searchQuery, sort, vendorId }) as TData, ...options });
export const useVendorsServiceGetVendorStatementOfAccount = <TData = Common.VendorsServiceGetVendorStatementOfAccountDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ endDate, startDate, vendorId }: {
  endDate?: string;
  startDate?: string;
  vendorId: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseVendorsServiceGetVendorStatementOfAccountKeyFn({ endDate, startDate, vendorId }, queryKey), queryFn: () => VendorsService.getVendorStatementOfAccount({ endDate, startDate, vendorId }) as TData, ...options });
export const useInventoryServiceGetServiceCategory = <TData = Common.InventoryServiceGetServiceCategoryDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetServiceCategoryKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => InventoryService.getServiceCategory({ filters, pagination, sort }) as TData, ...options });
export const useInventoryServiceGetServices = <TData = Common.InventoryServiceGetServicesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetServicesKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => InventoryService.getServices({ filters, pagination, sort }) as TData, ...options });
export const useInventoryServiceGetLocations = <TData = Common.InventoryServiceGetLocationsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetLocationsKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getLocations({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetBrands = <TData = Common.InventoryServiceGetBrandsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetBrandsKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getBrands({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetProductCategories = <TData = Common.InventoryServiceGetProductCategoriesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetProductCategoriesKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getProductCategories({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetProductSubcategory = <TData = Common.InventoryServiceGetProductSubcategoryDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetProductSubcategoryKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getProductSubcategory({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetProductParentCategory = <TData = Common.InventoryServiceGetProductParentCategoryDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetProductParentCategoryKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getProductParentCategory({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetProductCategoryOptionItems = <TData = Common.InventoryServiceGetProductCategoryOptionItemsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetProductCategoryOptionItemsKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getProductCategoryOptionItems({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetProducts = <TData = Common.InventoryServiceGetProductsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetProductsKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getProducts({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetTotalProducts = <TData = Common.InventoryServiceGetTotalProductsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetTotalProductsKeyFn(queryKey), queryFn: () => InventoryService.getTotalProducts() as TData, ...options });
export const useInventoryServiceGetAllProducts = <TData = Common.InventoryServiceGetAllProductsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetAllProductsKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getAllProducts({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetShopProducts = <TData = Common.InventoryServiceGetShopProductsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, search }: {
  limit: number;
  search?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetShopProductsKeyFn({ limit, search }, queryKey), queryFn: () => InventoryService.getShopProducts({ limit, search }) as TData, ...options });
export const useInventoryServiceGetProduct = <TData = Common.InventoryServiceGetProductDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetProductKeyFn({ id }, queryKey), queryFn: () => InventoryService.getProduct({ id }) as TData, ...options });
export const useInventoryServiceGetProductVariantTypes = <TData = Common.InventoryServiceGetProductVariantTypesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetProductVariantTypesKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getProductVariantTypes({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetPurchaseOrders = <TData = Common.InventoryServiceGetPurchaseOrdersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetPurchaseOrdersKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getPurchaseOrders({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetPurchaseOrder = <TData = Common.InventoryServiceGetPurchaseOrderDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetPurchaseOrderKeyFn({ id }, queryKey), queryFn: () => InventoryService.getPurchaseOrder({ id }) as TData, ...options });
export const useInventoryServiceGetPurchaseOrderPayments = <TData = Common.InventoryServiceGetPurchaseOrderPaymentsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, purchaseOrderId, searchQuery, sort }: {
  filters: string;
  pagination: string;
  purchaseOrderId: number;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetPurchaseOrderPaymentsKeyFn({ filters, pagination, purchaseOrderId, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getPurchaseOrderPayments({ filters, pagination, purchaseOrderId, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetInventoryTransactions = <TData = Common.InventoryServiceGetInventoryTransactionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetInventoryTransactionsKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getInventoryTransactions({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetModels = <TData = Common.InventoryServiceGetModelsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetModelsKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getModels({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetModelCategories = <TData = Common.InventoryServiceGetModelCategoriesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetModelCategoriesKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getModelCategories({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useInventoryServiceGetInventoryStock = <TData = Common.InventoryServiceGetInventoryStockDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryServiceGetInventoryStockKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => InventoryService.getInventoryStock({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useSalesServiceGetInvoices = <TData = Common.SalesServiceGetInvoicesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSalesServiceGetInvoicesKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => SalesService.getInvoices({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useSalesServiceGetInvoiceDetails = <TData = Common.SalesServiceGetInvoiceDetailsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ idInvoice }: {
  idInvoice: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSalesServiceGetInvoiceDetailsKeyFn({ idInvoice }, queryKey), queryFn: () => SalesService.getInvoiceDetails({ idInvoice }) as TData, ...options });
export const useInventoryReportsServiceGetInventoryStockReport = <TData = Common.InventoryReportsServiceGetInventoryStockReportDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters?: string;
  pagination?: string;
  sort?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseInventoryReportsServiceGetInventoryStockReportKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => InventoryReportsService.getInventoryStockReport({ filters, pagination, sort }) as TData, ...options });
export const useReparationServiceGetChecklistItems = <TData = Common.ReparationServiceGetChecklistItemsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReparationServiceGetChecklistItemsKeyFn(queryKey), queryFn: () => ReparationService.getChecklistItems() as TData, ...options });
export const useReparationServiceGetReparationCases = <TData = Common.ReparationServiceGetReparationCasesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, offset, search, sort, status }: {
  limit: number;
  offset: number;
  search: string;
  sort: string;
  status: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReparationServiceGetReparationCasesKeyFn({ limit, offset, search, sort, status }, queryKey), queryFn: () => ReparationService.getReparationCases({ limit, offset, search, sort, status }) as TData, ...options });
export const useReparationServiceGetReparationCase = <TData = Common.ReparationServiceGetReparationCaseDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReparationServiceGetReparationCaseKeyFn({ id }, queryKey), queryFn: () => ReparationService.getReparationCase({ id }) as TData, ...options });
export const useCustomerServiceGetCustomers = <TData = Common.CustomerServiceGetCustomersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, searchQuery, sort }: {
  filters: string;
  pagination: string;
  searchQuery?: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCustomerServiceGetCustomersKeyFn({ filters, pagination, searchQuery, sort }, queryKey), queryFn: () => CustomerService.getCustomers({ filters, pagination, searchQuery, sort }) as TData, ...options });
export const useCustomerServiceGetCustomer = <TData = Common.CustomerServiceGetCustomerDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ idCustomer }: {
  idCustomer: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCustomerServiceGetCustomerKeyFn({ idCustomer }, queryKey), queryFn: () => CustomerService.getCustomer({ idCustomer }) as TData, ...options });
export const useAccountingServiceGetAccounts = <TData = Common.AccountingServiceGetAccountsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetAccountsKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getAccounts({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetPaymentAccounts = <TData = Common.AccountingServiceGetPaymentAccountsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetPaymentAccountsKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getPaymentAccounts({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetExpenseAccounts = <TData = Common.AccountingServiceGetExpenseAccountsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetExpenseAccountsKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getExpenseAccounts({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetTransactions = <TData = Common.AccountingServiceGetTransactionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetTransactionsKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getTransactions({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetExpenseCategories = <TData = Common.AccountingServiceGetExpenseCategoriesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetExpenseCategoriesKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getExpenseCategories({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetExpenses = <TData = Common.AccountingServiceGetExpensesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetExpensesKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getExpenses({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetTaxes = <TData = Common.AccountingServiceGetTaxesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetTaxesKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getTaxes({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetCurrencies = <TData = Common.AccountingServiceGetCurrenciesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetCurrenciesKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getCurrencies({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetBankAccounts = <TData = Common.AccountingServiceGetBankAccountsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ filters, pagination, sort }: {
  filters: string;
  pagination: string;
  sort: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetBankAccountsKeyFn({ filters, pagination, sort }, queryKey), queryFn: () => AccountingService.getBankAccounts({ filters, pagination, sort }) as TData, ...options });
export const useAccountingServiceGetBankAccount = <TData = Common.AccountingServiceGetBankAccountDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountingServiceGetBankAccountKeyFn({ id }, queryKey), queryFn: () => AccountingService.getBankAccount({ id }) as TData, ...options });
export const useAuthServiceLogin = <TData = Common.AuthServiceLoginMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { password: string; username: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { password: string; username: string; };
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.login({ requestBody }) as unknown as Promise<TData>, ...options });
export const useUsersServiceCreateUser = <TData = Common.UsersServiceCreateUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateUserInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateUserInput;
}, TContext>({ mutationFn: ({ requestBody }) => UsersService.createUser({ requestBody }) as unknown as Promise<TData>, ...options });
export const useUsersServiceCreateRole = <TData = Common.UsersServiceCreateRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateRoleInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateRoleInput;
}, TContext>({ mutationFn: ({ requestBody }) => UsersService.createRole({ requestBody }) as unknown as Promise<TData>, ...options });
export const useSettingsServiceCreateProductVariantType = <TData = Common.SettingsServiceCreateProductVariantTypeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateProductVariantTypeInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateProductVariantTypeInput;
}, TContext>({ mutationFn: ({ requestBody }) => SettingsService.createProductVariantType({ requestBody }) as unknown as Promise<TData>, ...options });
export const useSettingsServiceCreateProductVariantValue = <TData = Common.SettingsServiceCreateProductVariantValueMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateProductVariantValueInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateProductVariantValueInput;
}, TContext>({ mutationFn: ({ requestBody }) => SettingsService.createProductVariantValue({ requestBody }) as unknown as Promise<TData>, ...options });
export const useSettingsServiceBackupDatabaseController = <TData = Common.SettingsServiceBackupDatabaseControllerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => SettingsService.backupDatabaseController() as unknown as Promise<TData>, ...options });
export const useSettingsServiceRestoreDatabase = <TData = Common.SettingsServiceRestoreDatabaseMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData: { file: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData: { file: string; };
}, TContext>({ mutationFn: ({ formData }) => SettingsService.restoreDatabase({ formData }) as unknown as Promise<TData>, ...options });
export const useVendorServiceCreateVendor = <TData = Common.VendorServiceCreateVendorMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateVendorInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateVendorInput;
}, TContext>({ mutationFn: ({ requestBody }) => VendorService.createVendor({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateServiceCategory = <TData = Common.InventoryServiceCreateServiceCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateServiceCategoryInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateServiceCategoryInput;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createServiceCategory({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateService = <TData = Common.InventoryServiceCreateServiceMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: unknown;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createService({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateLocation = <TData = Common.InventoryServiceCreateLocationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: unknown;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createLocation({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateBrand = <TData = Common.InventoryServiceCreateBrandMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateBrandInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateBrandInput;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createBrand({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateProductCategory = <TData = Common.InventoryServiceCreateProductCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: unknown;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createProductCategory({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateProduct = <TData = Common.InventoryServiceCreateProductMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateProductInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateProductInput;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createProduct({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceDownloadProductList = <TData = Common.InventoryServiceDownloadProductListMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => InventoryService.downloadProductList() as unknown as Promise<TData>, ...options });
export const useInventoryServiceGetLocationProductQuantity = <TData = Common.InventoryServiceGetLocationProductQuantityMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => InventoryService.getLocationProductQuantity({ id }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateProductVariant = <TData = Common.InventoryServiceCreateProductVariantMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: ICreateProductVariantInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: ICreateProductVariantInput;
}, TContext>({ mutationFn: ({ id, requestBody }) => InventoryService.createProductVariant({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreatePurchaseOrder = <TData = Common.InventoryServiceCreatePurchaseOrderMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreatePurchaseOrderInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreatePurchaseOrderInput;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createPurchaseOrder({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceApprovePurchaseOrder = <TData = Common.InventoryServiceApprovePurchaseOrderMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  purchaseOrderId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  purchaseOrderId: number;
}, TContext>({ mutationFn: ({ purchaseOrderId }) => InventoryService.approvePurchaseOrder({ purchaseOrderId }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceRecordPurchaseOrderPayment = <TData = Common.InventoryServiceRecordPurchaseOrderPaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  purchaseOrderId: number;
  requestBody: ICreatePurchaseOrderPaymentInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  purchaseOrderId: number;
  requestBody: ICreatePurchaseOrderPaymentInput;
}, TContext>({ mutationFn: ({ purchaseOrderId, requestBody }) => InventoryService.recordPurchaseOrderPayment({ purchaseOrderId, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceUpdatePurchaseDeliveryStatus = <TData = Common.InventoryServiceUpdatePurchaseDeliveryStatusMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  purchaseOrderId: number;
  requestBody: IUpdatePurchaseOrderDeliveryStatusInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  purchaseOrderId: number;
  requestBody: IUpdatePurchaseOrderDeliveryStatusInput;
}, TContext>({ mutationFn: ({ purchaseOrderId, requestBody }) => InventoryService.updatePurchaseDeliveryStatus({ purchaseOrderId, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateDeliveryNote = <TData = Common.InventoryServiceCreateDeliveryNoteMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  purchaseOrderId: number;
  requestBody: ICreateDeliveryNoteInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  purchaseOrderId: number;
  requestBody: ICreateDeliveryNoteInput;
}, TContext>({ mutationFn: ({ purchaseOrderId, requestBody }) => InventoryService.createDeliveryNote({ purchaseOrderId, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateInventoryTransaction = <TData = Common.InventoryServiceCreateInventoryTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateInventoryTransactionInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateInventoryTransactionInput;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createInventoryTransaction({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateModel = <TData = Common.InventoryServiceCreateModelMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: unknown;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createModel({ requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceCreateModelCategory = <TData = Common.InventoryServiceCreateModelCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateModelCategoryInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateModelCategoryInput;
}, TContext>({ mutationFn: ({ requestBody }) => InventoryService.createModelCategory({ requestBody }) as unknown as Promise<TData>, ...options });
export const useSalesServiceCreateInvoice = <TData = Common.SalesServiceCreateInvoiceMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateInvoiceInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateInvoiceInput;
}, TContext>({ mutationFn: ({ requestBody }) => SalesService.createInvoice({ requestBody }) as unknown as Promise<TData>, ...options });
export const useSalesServiceCreateInvoicePayment = <TData = Common.SalesServiceCreateInvoicePaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idInvoice: number;
  requestBody: { referenceNumber: string; amount: number; account: number; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idInvoice: number;
  requestBody: { referenceNumber: string; amount: number; account: number; };
}, TContext>({ mutationFn: ({ idInvoice, requestBody }) => SalesService.createInvoicePayment({ idInvoice, requestBody }) as unknown as Promise<TData>, ...options });
export const useSalesServiceCancelInvoice = <TData = Common.SalesServiceCancelInvoiceMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idInvoice: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idInvoice: number;
}, TContext>({ mutationFn: ({ idInvoice }) => SalesService.cancelInvoice({ idInvoice }) as unknown as Promise<TData>, ...options });
export const useReparationServiceCreateReparationCase = <TData = Common.ReparationServiceCreateReparationCaseMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData: { brandId: string; firstName: string; lastName: string; email: string; primaryPhone: string; secondaryPhone: string; address: string; model: string; imeiNumber: string; deviceLockCode: string; devicePattern: string; frontView: Blob | File; backView: Blob | File; leftSide: Blob | File; rightSide: Blob | File; devicePowerStatus: string; deviceOffReason: string; testFee: string; testIsRequiredBeforeRepair: string; additionalNotes: string; reportedCaseIssue: string; issueChecklistItems: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData: { brandId: string; firstName: string; lastName: string; email: string; primaryPhone: string; secondaryPhone: string; address: string; model: string; imeiNumber: string; deviceLockCode: string; devicePattern: string; frontView: Blob | File; backView: Blob | File; leftSide: Blob | File; rightSide: Blob | File; devicePowerStatus: string; deviceOffReason: string; testFee: string; testIsRequiredBeforeRepair: string; additionalNotes: string; reportedCaseIssue: string; issueChecklistItems: string; };
}, TContext>({ mutationFn: ({ formData }) => ReparationService.createReparationCase({ formData }) as unknown as Promise<TData>, ...options });
export const useReparationServiceCreateOrUpdateReview = <TData = Common.ReparationServiceCreateOrUpdateReviewMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: ICreateOrUpdateReparationCaseReview;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: ICreateOrUpdateReparationCaseReview;
}, TContext>({ mutationFn: ({ id, requestBody }) => ReparationService.createOrUpdateReview({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useReparationServiceCreateReparationCaseItem = <TData = Common.ReparationServiceCreateReparationCaseItemMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: ICreateReparationCaseItemInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: ICreateReparationCaseItemInput;
}, TContext>({ mutationFn: ({ id, requestBody }) => ReparationService.createReparationCaseItem({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCustomerServiceCreateCustomer = <TData = Common.CustomerServiceCreateCustomerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateCustomerInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateCustomerInput;
}, TContext>({ mutationFn: ({ requestBody }) => CustomerService.createCustomer({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceCreateTransaction = <TData = Common.AccountingServiceCreateTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateTransactionInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateTransactionInput;
}, TContext>({ mutationFn: ({ requestBody }) => AccountingService.createTransaction({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceCreateExpenseCategory = <TData = Common.AccountingServiceCreateExpenseCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateExpenseCategoryInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateExpenseCategoryInput;
}, TContext>({ mutationFn: ({ requestBody }) => AccountingService.createExpenseCategory({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceCreateExpense = <TData = Common.AccountingServiceCreateExpenseMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateExpenseInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateExpenseInput;
}, TContext>({ mutationFn: ({ requestBody }) => AccountingService.createExpense({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceCreateTax = <TData = Common.AccountingServiceCreateTaxMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateTaxInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateTaxInput;
}, TContext>({ mutationFn: ({ requestBody }) => AccountingService.createTax({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceCreateCurrency = <TData = Common.AccountingServiceCreateCurrencyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateCurrencyInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateCurrencyInput;
}, TContext>({ mutationFn: ({ requestBody }) => AccountingService.createCurrency({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceCreateBankAccount = <TData = Common.AccountingServiceCreateBankAccountMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICreateBankAccountInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICreateBankAccountInput;
}, TContext>({ mutationFn: ({ requestBody }) => AccountingService.createBankAccount({ requestBody }) as unknown as Promise<TData>, ...options });
export const useUsersServiceUpdateUser = <TData = Common.UsersServiceUpdateUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: IUpdateUserInput;
  userId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: IUpdateUserInput;
  userId: number;
}, TContext>({ mutationFn: ({ requestBody, userId }) => UsersService.updateUser({ requestBody, userId }) as unknown as Promise<TData>, ...options });
export const useUsersServiceUpdateRole = <TData = Common.UsersServiceUpdateRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: IUpdateRoleInput;
  roleId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: IUpdateRoleInput;
  roleId: number;
}, TContext>({ mutationFn: ({ requestBody, roleId }) => UsersService.updateRole({ requestBody, roleId }) as unknown as Promise<TData>, ...options });
export const useSettingsServiceUpdateProductVariantType = <TData = Common.SettingsServiceUpdateProductVariantTypeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idProductVariantType: string;
  requestBody: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idProductVariantType: string;
  requestBody: unknown;
}, TContext>({ mutationFn: ({ idProductVariantType, requestBody }) => SettingsService.updateProductVariantType({ idProductVariantType, requestBody }) as unknown as Promise<TData>, ...options });
export const useSettingsServiceUpdateProductVariantValue = <TData = Common.SettingsServiceUpdateProductVariantValueMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idProductVariantValue: string;
  requestBody: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idProductVariantValue: string;
  requestBody: unknown;
}, TContext>({ mutationFn: ({ idProductVariantValue, requestBody }) => SettingsService.updateProductVariantValue({ idProductVariantValue, requestBody }) as unknown as Promise<TData>, ...options });
export const useSettingsServiceUpdateCompanyDetails = <TData = Common.SettingsServiceUpdateCompanyDetailsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ICompanyDetailsInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ICompanyDetailsInput;
}, TContext>({ mutationFn: ({ requestBody }) => SettingsService.updateCompanyDetails({ requestBody }) as unknown as Promise<TData>, ...options });
export const useVendorsServiceUpdateVendor = <TData = Common.VendorsServiceUpdateVendorMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: IUpdateVendorInput;
  vendorId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: IUpdateVendorInput;
  vendorId: number;
}, TContext>({ mutationFn: ({ requestBody, vendorId }) => VendorsService.updateVendor({ requestBody, vendorId }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceUpdateLocation = <TData = Common.InventoryServiceUpdateLocationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idLocation: string;
  requestBody: IUpdateLocationInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idLocation: string;
  requestBody: IUpdateLocationInput;
}, TContext>({ mutationFn: ({ idLocation, requestBody }) => InventoryService.updateLocation({ idLocation, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceUpdateBrand = <TData = Common.InventoryServiceUpdateBrandMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idBrand: string;
  requestBody: IUpdateBrandInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idBrand: string;
  requestBody: IUpdateBrandInput;
}, TContext>({ mutationFn: ({ idBrand, requestBody }) => InventoryService.updateBrand({ idBrand, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceUpdateProductCategory = <TData = Common.InventoryServiceUpdateProductCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idProductCategory: string;
  requestBody: IUpdateProductCategoryInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idProductCategory: string;
  requestBody: IUpdateProductCategoryInput;
}, TContext>({ mutationFn: ({ idProductCategory, requestBody }) => InventoryService.updateProductCategory({ idProductCategory, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceUpdateProduct = <TData = Common.InventoryServiceUpdateProductMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: IUpdateProductInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: IUpdateProductInput;
}, TContext>({ mutationFn: ({ id, requestBody }) => InventoryService.updateProduct({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceUpdateProductVariant = <TData = Common.InventoryServiceUpdateProductVariantMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: IUpdateProductInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: IUpdateProductInput;
}, TContext>({ mutationFn: ({ id, requestBody }) => InventoryService.updateProductVariant({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceUpdateModel = <TData = Common.InventoryServiceUpdateModelMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idModel: string;
  requestBody: IUpdateModelInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idModel: string;
  requestBody: IUpdateModelInput;
}, TContext>({ mutationFn: ({ idModel, requestBody }) => InventoryService.updateModel({ idModel, requestBody }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceUpdateModelCategory = <TData = Common.InventoryServiceUpdateModelCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idModelCategory: string;
  requestBody: IUpdateModelCategoryInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idModelCategory: string;
  requestBody: IUpdateModelCategoryInput;
}, TContext>({ mutationFn: ({ idModelCategory, requestBody }) => InventoryService.updateModelCategory({ idModelCategory, requestBody }) as unknown as Promise<TData>, ...options });
export const useReparationServiceUpdateReparationCaseItem = <TData = Common.ReparationServiceUpdateReparationCaseItemMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  itemId: number;
  requestBody: IUpdateReparationCaseItemInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  itemId: number;
  requestBody: IUpdateReparationCaseItemInput;
}, TContext>({ mutationFn: ({ id, itemId, requestBody }) => ReparationService.updateReparationCaseItem({ id, itemId, requestBody }) as unknown as Promise<TData>, ...options });
export const useCustomerServiceUpdateCustomer = <TData = Common.CustomerServiceUpdateCustomerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idCustomer: string;
  requestBody: IUpdateCustomerInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idCustomer: string;
  requestBody: IUpdateCustomerInput;
}, TContext>({ mutationFn: ({ idCustomer, requestBody }) => CustomerService.updateCustomer({ idCustomer, requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceUpdateTransaction = <TData = Common.AccountingServiceUpdateTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idTransaction: number;
  requestBody: IUpdateTransactionInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idTransaction: number;
  requestBody: IUpdateTransactionInput;
}, TContext>({ mutationFn: ({ idTransaction, requestBody }) => AccountingService.updateTransaction({ idTransaction, requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceUpdateExpense = <TData = Common.AccountingServiceUpdateExpenseMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idExpense: string;
  requestBody: CreateExpenseInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idExpense: string;
  requestBody: CreateExpenseInput;
}, TContext>({ mutationFn: ({ idExpense, requestBody }) => AccountingService.updateExpense({ idExpense, requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceUpdateExpenseCategory = <TData = Common.AccountingServiceUpdateExpenseCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idExpenseCategory: string;
  requestBody: CreateExpenseCategoryInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idExpenseCategory: string;
  requestBody: CreateExpenseCategoryInput;
}, TContext>({ mutationFn: ({ idExpenseCategory, requestBody }) => AccountingService.updateExpenseCategory({ idExpenseCategory, requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceUpdateTax = <TData = Common.AccountingServiceUpdateTaxMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idTax: number;
  requestBody: IUpdateTaxInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idTax: number;
  requestBody: IUpdateTaxInput;
}, TContext>({ mutationFn: ({ idTax, requestBody }) => AccountingService.updateTax({ idTax, requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceUpdateCurrency = <TData = Common.AccountingServiceUpdateCurrencyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: ICreateCurrencyInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: ICreateCurrencyInput;
}, TContext>({ mutationFn: ({ id, requestBody }) => AccountingService.updateCurrency({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceUpdateBankAccount = <TData = Common.AccountingServiceUpdateBankAccountMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: IUpdateBankAccountInput;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: IUpdateBankAccountInput;
}, TContext>({ mutationFn: ({ id, requestBody }) => AccountingService.updateBankAccount({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useUsersServiceDeleteUser = <TData = Common.UsersServiceDeleteUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  userId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  userId: number;
}, TContext>({ mutationFn: ({ userId }) => UsersService.deleteUser({ userId }) as unknown as Promise<TData>, ...options });
export const useUsersServiceDeleteRole = <TData = Common.UsersServiceDeleteRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  roleId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  roleId: number;
}, TContext>({ mutationFn: ({ roleId }) => UsersService.deleteRole({ roleId }) as unknown as Promise<TData>, ...options });
export const useSettingsServiceDeleteProductVariantType = <TData = Common.SettingsServiceDeleteProductVariantTypeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idProductVariantType: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idProductVariantType: string;
}, TContext>({ mutationFn: ({ idProductVariantType }) => SettingsService.deleteProductVariantType({ idProductVariantType }) as unknown as Promise<TData>, ...options });
export const useSettingsServiceDeleteProductVariantValue = <TData = Common.SettingsServiceDeleteProductVariantValueMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idProductVariantValue: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idProductVariantValue: string;
}, TContext>({ mutationFn: ({ idProductVariantValue }) => SettingsService.deleteProductVariantValue({ idProductVariantValue }) as unknown as Promise<TData>, ...options });
export const useVendorsServiceDeleteVendor = <TData = Common.VendorsServiceDeleteVendorMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  vendorId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  vendorId: number;
}, TContext>({ mutationFn: ({ vendorId }) => VendorsService.deleteVendor({ vendorId }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceDeleteLocation = <TData = Common.InventoryServiceDeleteLocationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idLocation: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idLocation: string;
}, TContext>({ mutationFn: ({ idLocation }) => InventoryService.deleteLocation({ idLocation }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceDeleteBrand = <TData = Common.InventoryServiceDeleteBrandMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idBrand: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idBrand: string;
}, TContext>({ mutationFn: ({ idBrand }) => InventoryService.deleteBrand({ idBrand }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceDeleteProductCategory = <TData = Common.InventoryServiceDeleteProductCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idProductCategory: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idProductCategory: string;
}, TContext>({ mutationFn: ({ idProductCategory }) => InventoryService.deleteProductCategory({ idProductCategory }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceDeleteProduct = <TData = Common.InventoryServiceDeleteProductMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idProduct: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idProduct: number;
}, TContext>({ mutationFn: ({ idProduct }) => InventoryService.deleteProduct({ idProduct }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceDeleteProductVariant = <TData = Common.InventoryServiceDeleteProductVariantMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => InventoryService.deleteProductVariant({ id }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceDeletePurchaseOrder = <TData = Common.InventoryServiceDeletePurchaseOrderMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  purchaseOrderId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  purchaseOrderId: number;
}, TContext>({ mutationFn: ({ purchaseOrderId }) => InventoryService.deletePurchaseOrder({ purchaseOrderId }) as unknown as Promise<TData>, ...options });
export const useInventoryServiceDeleteModel = <TData = Common.InventoryServiceDeleteModelMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idModel: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idModel: number;
}, TContext>({ mutationFn: ({ idModel }) => InventoryService.deleteModel({ idModel }) as unknown as Promise<TData>, ...options });
export const useReparationServiceDeleteReparationCaseItem = <TData = Common.ReparationServiceDeleteReparationCaseItemMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  itemId: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  itemId: number;
}, TContext>({ mutationFn: ({ id, itemId }) => ReparationService.deleteReparationCaseItem({ id, itemId }) as unknown as Promise<TData>, ...options });
export const useCustomerServiceDeleteCustomer = <TData = Common.CustomerServiceDeleteCustomerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idCustomer: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idCustomer: string;
}, TContext>({ mutationFn: ({ idCustomer }) => CustomerService.deleteCustomer({ idCustomer }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceDeleteTransaction = <TData = Common.AccountingServiceDeleteTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idTransaction: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idTransaction: number;
}, TContext>({ mutationFn: ({ idTransaction }) => AccountingService.deleteTransaction({ idTransaction }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceDeleteExpense = <TData = Common.AccountingServiceDeleteExpenseMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idExpense: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idExpense: string;
}, TContext>({ mutationFn: ({ idExpense }) => AccountingService.deleteExpense({ idExpense }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceDeleteExpenseCategory = <TData = Common.AccountingServiceDeleteExpenseCategoryMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => AccountingService.deleteExpenseCategory({ id }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceDeleteTax = <TData = Common.AccountingServiceDeleteTaxMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  idTax: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  idTax: number;
}, TContext>({ mutationFn: ({ idTax }) => AccountingService.deleteTax({ idTax }) as unknown as Promise<TData>, ...options });
export const useAccountingServiceDeleteBankAccount = <TData = Common.AccountingServiceDeleteBankAccountMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => AccountingService.deleteBankAccount({ id }) as unknown as Promise<TData>, ...options });
