import {
  Button,
  Col,
  Divider,
  Flex,
  Input,
  message,
  Row,
  Space,
  Table,
  TableColumnType,
  Typography,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  useInventoryServiceDeleteLocation,
  useInventoryServiceGetLocations,
  useInventoryServiceGetLocationsKey,
} from "api/queries";
import queryString from "qs";
import { useQueryClient } from "@tanstack/react-query";
import { CreateLocationModal } from "./CreateLocationModal";
import { EditLocationModal } from "./EditLocationModal";
import { TableActions } from "components/common";
import useFilters, { FilterType, IFilterItem } from "hooks/UseFilter";

export const LocationListingPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [createLocationModalOpen, setCreateLocationModalOpen] = useState(false);
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [sortQuery, setSortQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const queryClient = useQueryClient();

  const filtersDef: IFilterItem[] = [
    {
      key: "locationName",
      label: "Name",
      type: FilterType.Text,
    },
    {
      key: "locationDescription",
      label: "Description",
      type: FilterType.Text,
    },
    {
      key: "locationType",
      label: "Type",
      type: FilterType.MultiSelect,
      options: [
        { label: "Warehouse", value: "WAREHOUSE", key: "WAREHOUSE" },
        { label: "Store", value: "STORE", key: "STORE" },
        { label: "Office", value: "OFFICE", key: "OFFICE" },
        { label: "Factory", value: "FACTORY", key: "FACTORY" },
        { label: "Other", value: "OTHER", key: "OTHER" },
      ],
    },
  ];
  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, isPending, isFetching, refetch } =
    useInventoryServiceGetLocations(
      {
        filters: queryString.stringify(filtersQuery),
        pagination: queryString.stringify({
          offset: (page - 1) * limit,
          limit,
        }),
        sort: sortQuery,
        searchQuery,
      },
      [useInventoryServiceGetLocationsKey]
    );

  const { mutate: deleteLocationMutation, isPending: isDeletePending } =
    useInventoryServiceDeleteLocation({
      onSuccess: () => {
        message.success("Location deleted successfully");
        refetch();
      },
      onError: (err: any) => {
        message.error(err?.body?.message || err?.message || err);
      },
    });

  useEffect(() => {
    refetch();
  }, [page, limit, sortQuery, filtersQuery, searchQuery]);

  const columns: TableColumnType<any>[] = [
    {
      key: "locationName",
      title: "Name",
      dataIndex: "locationName",
      sorter: true,
    },
    {
      key: "locationDescription",
      title: "Description",
      dataIndex: "locationDescription",
      sorter: true,
    },
    {
      key: "locationType",
      title: "Type",
      dataIndex: "locationType",
      sorter: true,
    },
    {
      key: "isActive",
      title: "Is Active",
      dataIndex: "isActive",
      render: (value) => (value ? "Yes" : "No"),
      sorter: true,
    },
    {
      key: "action",
      title: "Actions",
      render: (_text, record) => (
        <TableActions
          onEdit={() => handleOpenEditLocationModal(record)}
          onDeletePopConfirm={() => handleDeleteLocation(record.idLocation)}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This location will delete permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];

  const handleOpenEditLocationModal = (location: any) => {
    setEditLocationModalOpen(true);
    setSelectedLocation(location);
  };

  const handleCloseEditLocationModal = () => {
    setEditLocationModalOpen(false);
  };

  const handleDeleteLocation = async (id: string) => {
    await deleteLocationMutation({
      idLocation: id,
    });
    refetch();
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Flex justify="start" align="center">
            <Typography.Title level={2} style={{ margin: 0 }}>
              Locations
            </Typography.Title>
          </Flex>
        </Col>
        <Col xs={12}>
          <Flex justify="end" align="center">
            <Space>
              {FilterButton}
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={() => setCreateLocationModalOpen(true)}
              >
                Add New
              </Button>
            </Space>
          </Flex>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24} style={{ marginBottom: 15 }}>
          <Input.Search
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            style={{ flex: 1 }}
          />
        </Col>

        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isPending || isFetching}
            key={"location-list"}
            rowKey={(record) => record.idLocation + "location-list"}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              onChange: (page) => setPage(page),
              onShowSizeChange: (_, limit) => setLimit(limit),
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CreateLocationModal
        isOpen={createLocationModalOpen}
        onClose={() => setCreateLocationModalOpen(false)}
        queryClient={queryClient}
      />

      <EditLocationModal
        isOpen={editLocationModalOpen}
        onClose={handleCloseEditLocationModal}
        queryClient={queryClient}
        defaultValues={selectedLocation}
      />
    </motion.div>
  );
};

export default LocationListingPage;
