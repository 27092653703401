import {
  Button,
  Col,
  Divider,
  Flex,
  Input,
  message,
  Row,
  Space,
  Table,
  TableColumnType,
  Typography,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  useInventoryServiceDeleteBrand,
  useInventoryServiceGetBrands,
  useInventoryServiceGetBrandsKey,
} from "api/queries";
import queryString from "query-string";
import { useQueryClient } from "@tanstack/react-query";
import { CreateBrandModal } from "./CreateBrandModal";
import { TableActions } from "components/common";
import { EditBrandModal } from "./EditBrandModal";
import useFilters, { FilterType, IFilterItem } from "hooks/UseFilter";
import QueryString from "qs";

export const BrandListingPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [createBrandModalOpen, setCreateBrandModalOpen] = useState(false);
  const [editBrandModalOpen, setEditBrandModalOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const [sortQuery, setSortQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const queryClient = useQueryClient();

  const filtersDef: IFilterItem[] = [
    {
      key: "brandName",
      label: "Brand Name",
      type: FilterType.Text,
    },
    {
      key: "brandDescription",
      label: "Description",
      type: FilterType.Text,
    },
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, isPending, isFetching, refetch } =
    useInventoryServiceGetBrands(
      {
        filters: QueryString.stringify(filtersQuery),
        pagination: queryString.stringify({
          offset: (page - 1) * limit,
          limit,
        }),
        sort: sortQuery,
        searchQuery,
      },
      [useInventoryServiceGetBrandsKey]
    );

  const { mutate: deleteBrandMutation, isPending: isDeletePending } =
    useInventoryServiceDeleteBrand({
      onSuccess: () => {
        message.success("Brand deleted successfully");
        refetch();
      },
      onError: (err: any) => {
        message.error(err?.body?.message || err?.message || err);
      },
    });

  const handleOpenEditBrandModal = (brand: any) => {
    setEditBrandModalOpen(true);
    setSelectedBrand(brand);
  };

  const handleCloseEditBrandModal = () => {
    setEditBrandModalOpen(false);
  };

  const handleCreateModalClose = async (success?: boolean) => {
    if (success)
      await queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetBrandsKey],
        type: "all",
      });
    setCreateBrandModalOpen(false);
  };

  const handleDeleteBrand = async (id: string) => {
    await deleteBrandMutation({
      idBrand: id,
    });
    refetch();
  };

  const columns: TableColumnType<any>[] = [
    {
      key: "brandName",
      title: "Name",
      dataIndex: "brandName",
      width: 200,
      sorter: true,
    },
    {
      key: "brandDescription",
      title: "Description",
      dataIndex: "brandDescription",
      width: 200,
      sorter: true,
    },
    {
      key: "isActive",
      title: "Is Active",
      dataIndex: "isActive",
      render: (value) => (value ? "Yes" : "No"),
      width: 200,
      sorter: true,
    },
    {
      key: "actions",
      title: "Actions",
      render: (_r, record) => (
        <TableActions
          onDeletePopConfirm={() => handleDeleteBrand(record.idBrand)}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This brand will delete permanently"
          onDeleteLoading={isDeletePending}
          onEdit={() => handleOpenEditBrandModal(record)}
        />
      ),
      width: 200,
    },
  ];

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetBrandsKey],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortQuery, filtersQuery, searchQuery]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Flex justify="start" align="center">
            <Typography.Title level={2} style={{ margin: 0 }}>
              Brands
            </Typography.Title>
          </Flex>
        </Col>
        <Col xs={12}>
          <Flex justify="end" align="center">
            <Space>
              {FilterButton}
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={() => setCreateBrandModalOpen(true)}
              >
                Add New
              </Button>
            </Space>
          </Flex>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24} style={{ marginBottom: 15 }}>
          <Input.Search
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            style={{ flex: 1 }}
          />
        </Col>

        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isPending || isFetching}
            scroll={{ x: "max-content" }}
            key={"brand-list"}
            rowKey={(item) => item.idBrand}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              onChange: (page) => setPage(page),
              onShowSizeChange: (_, limit) => setLimit(limit),
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CreateBrandModal
        isOpen={createBrandModalOpen}
        onClose={handleCreateModalClose}
      />

      <EditBrandModal
        isOpen={editBrandModalOpen}
        onClose={handleCloseEditBrandModal}
        queryClient={queryClient}
        defaultValues={selectedBrand}
      />
    </motion.div>
  );
};

export default BrandListingPage;
