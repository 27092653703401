import { QueryClient } from "@tanstack/react-query";
import { Col, Form, Input, Modal, notification, Row, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceGetProductCategoriesKey,
  useInventoryServiceGetProductCategoryOptionItems,
  useInventoryServiceGetProductCategoryOptionItemsKey,
  useInventoryServiceUpdateProductCategory,
} from "api/queries";
import { useProductCategoryFormItem } from "components/common/ProductCategorySelect";
import { useEffect } from "react";

export const EditProductCategoryModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
  defaultValues: any;
}> = ({ isOpen, onClose, queryClient, defaultValues }) => {
  const [form] = Form.useForm();

  const { mutate, isPending } = useInventoryServiceUpdateProductCategory({
    onError: (error: any) => {
      notification.error({
        message: "Error",
        description:
          error?.body?.message ||
          error?.response?.data?.message ||
          error?.message,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          useInventoryServiceGetProductCategoriesKey,
          useInventoryServiceGetProductCategoryOptionItemsKey,
        ],
        refetchType: "all",
      });
      onClose();
    },
  });

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      idProductCategory: defaultValues.idProductCategory,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
      parentCategory: defaultValues?.parentCategory?.idProductCategory || null,
    });
  });

  const {
    data,
    isLoading,
    isRefetching,
    refetch: refetchCategoryOptions,
  } = useInventoryServiceGetProductCategoryOptionItems(
    {
      filters: "",
      pagination: "",
      sort: "",
    },
    [useInventoryServiceGetProductCategoryOptionItemsKey],
    {
      refetchOnMount: true,
    }
  );

  const [SearchItemComponent, options, isValidParent]: any =
    useProductCategoryFormItem(data, isLoading, isRefetching);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Edit Product Category"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
      afterOpenChange={() => {
        refetchCategoryOptions();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="edit-product-category-form"
      >
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item
              name="productCategoryName"
              label="Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="parentCategory"
              label="Parent Category"
              rules={[
                {
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (
                      !isValidParent(
                        options,
                        defaultValues?.idProductCategory,
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error("Invalid parent category")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <SearchItemComponent
                onClear={() => form.setFieldValue("parentCategory", null)}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="productCategoryDescription" label="Description">
              <TextArea />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="isActive"
              label="Is Active"
              rules={[{ required: true }]}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
