import React from "react";
import { Tag } from "antd";
import { ReparationCaseStatus } from "api/requests";


interface StatusTagProps {
  status: ReparationCaseStatus;
}

// Define color and display name mappings
const statusColors: { [key in ReparationCaseStatus]: string } = {
  PENDING: "orange",
  CHECKING: "blue",
  REVISED: "purple",
  PRICED: "gold",
  REPAIRING: "cyan",
  COMPLETED: "green",
  PAID: "geekblue",
  CANCELLED: "red",
};

const statusDisplayNames: { [key in ReparationCaseStatus]: string } = {
  PENDING: "Pending",
  CHECKING: "Checking",
  REVISED: "Revised",
  PRICED: "Priced",
  REPAIRING: "Repairing",
  COMPLETED: "Completed",
  PAID: "Paid",
  CANCELLED: "Cancelled",
};

const CaseStatusTag: React.FC<StatusTagProps> = ({ status }) => {
  return (
    <Tag color={statusColors[status]}>
      {statusDisplayNames[status]}
    </Tag>
  );
};

export default CaseStatusTag;