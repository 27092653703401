import { Modal, Form, Input, Button, notification } from "antd";
import { useReparationServiceCreateOrUpdateReview } from "api/queries";
import { FC } from "react";

interface CreateRemarkModalProps {
  visible: boolean; // Visibility of the modal
  onClose: () => void; // Function to close the modal
  caseId: string; // Case ID to associate the remark
}

interface RemarkFormValues {
  description: string; // Description of the remark
  pricing?: number; // Optional pricing field
}

export const CreateRemarkModal: FC<CreateRemarkModalProps> = ({
  visible,
  onClose,
  caseId,
}) => {
  const [form] = Form.useForm<RemarkFormValues>();

  // Destructure mutate and isPending from the custom hook
  const { mutate, isPending } = useReparationServiceCreateOrUpdateReview();

  // Form submission handler
  const handleFormSubmit = (values: RemarkFormValues) => {
    mutate(
      { requestBody: values, id: +caseId }, // Pass the caseId and form values
      {
        onSuccess: () => {
          notification.success({
            message: "Remark Created",
            description: "The remark was successfully created.",
          });
          form.resetFields(); // Reset form fields on success
          onClose(); // Close the modal
        },
        onError: (error: any) => {
          const errorMessage =
            error?.response?.data?.message || "An unexpected error occurred.";
          notification.error({
            message: "Error Creating Remark",
            description: errorMessage,
          });
        },
      }
    );
  };

  return (
    <Modal
      title="Create Remark"
      visible={visible}
      onCancel={onClose} // Close modal on cancel
      footer={null} // No default footer
      destroyOnClose // Destroy modal content when closed
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit} // Form submission handler
      >
        {/* Description Field */}
        <Form.Item
          label="Remark Description"
          name="description"
          rules={[{ required: true, message: "Please enter a description!" }]} // Validation
        >
          <Input.TextArea rows={4} placeholder="Enter your remark" />
        </Form.Item>

        {/* Pricing Field */}
        <Form.Item
          label="Pricing"
          name="pricing"
          rules={[
            {
              type: "number",
              transform: (value) => (value ? Number(value) : undefined),
              message: "Please enter a valid number.",
            },
          ]}
        >
          <Input placeholder="Enter pricing (optional)" type="number" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isPending} // Show loading state
            disabled={isPending} // Prevent duplicate submissions
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};