import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Radio,
  Checkbox,
  Row,
  Col,
  InputNumber,
  Table,
} from "antd";
import type { RadioChangeEvent } from "antd";
import { IGetChecklistItemsResponseItem } from "api/requests";

const DeviceStatusProblems: React.FC<{
  form: any;
  issues: any[];
  setIssues: any;
}> = ({ form, issues, setIssues }) => {
  const [devicePowerStatus, setDevicePowerStatus] = useState<"ON" | "OFF">(
    "OFF"
  );

  // Split issues list into two equal parts for the two tables
  const middleIndex = Math.ceil(issues.length / 2);
  const leftTableIssues = issues.slice(0, middleIndex);
  const rightTableIssues = issues.slice(middleIndex);

  const handleIssueChange = (title: string, checked: boolean) => {
    const updatedIssues = issues.map((issue) =>
      issue.itemTitle === title ? { ...issue, hasIssue: checked } : issue
    );
    setIssues(updatedIssues);
  };

  const handleDetailChange = (title: string, value: string) => {
    const updatedIssues = issues.map((issue) =>
      issue.itemTitle === title ? { ...issue, details: value } : issue
    );
    setIssues(updatedIssues);
  };

  const columns = [
    {
      title: "Issue Category",
      dataIndex: "itemTitle",
      key: "itemTitle",
      width: "40%",
      render: (text: string) => <strong>{text}</strong>,
    },
    {
      title: "Has Issue",
      dataIndex: "hasIssue",
      key: "hasIssue",
      render: (text: string, record: any) => (
        <Checkbox
          checked={record.hasIssue}
          onChange={(e) => handleIssueChange(record.itemTitle, e.target.checked)}
        />
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text: string, record: any) => (
        <Input
          placeholder="Describe the issue"
          disabled={!record.hasIssue}
          value={record.details}
          onChange={(e) => handleDetailChange(record.itemTitle, e.target.value)}
        />
      ),
    },
  ];

  const handlePowerStatusChange = (e: RadioChangeEvent) => {
    setDevicePowerStatus(e.target.value);
  };

  return (
    <>
      <h3>Device Status & Problems</h3>
      <hr style={{ borderColor: "#ED7D31" }} />

      <Form.Item label="Device Power Status" name={"devicePowerStatus"}>
        <Radio.Group
          onChange={handlePowerStatusChange}
          value={devicePowerStatus}
        >
          <Radio value="ON">Device is Powered On</Radio>
          <Radio value="OFF">Device is Powered Off</Radio>
        </Radio.Group>
      </Form.Item>

      {devicePowerStatus === "OFF" ? (
        // Show options when device is powered off
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Reason Device is Off" name="reasonDeviceIsOff">
              <Input placeholder="Select reason if off" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Test Fee (FCFA)" name="testFee">
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Enter test fee amount"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="testRequiredBeforeRepair" valuePropName="checked">
              <Checkbox>Test Required Before Repair</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Additional Notes" name="additionalNotes">
              <Input.TextArea rows={4} placeholder="Any additional notes" />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        // Show issues checklist when device is powered on
        <>
          <h4>Issues Checklist</h4>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Table
                bordered
                columns={columns}
                dataSource={leftTableIssues}
                pagination={false}
                rowKey="category"
                size="small"
              />
            </Col>
            <Col xs={24} md={12}>
              <Table
                bordered
                columns={columns}
                dataSource={rightTableIssues}
                pagination={false}
                rowKey="itemTitle"
                size="small"
              />
            </Col>
          </Row>
          <Form.Item
            label="Additional Notes"
            name="additionalNotes"
            style={{ marginTop: 16 }}
          >
            <Input.TextArea rows={4} placeholder="Any additional notes" />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default DeviceStatusProblems;
