import { QueryClient } from "@tanstack/react-query";
import { Alert, Col, Form, Input, Modal, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceCreateProductCategory,
  useInventoryServiceGetProductCategoriesKey,
  useInventoryServiceGetProductCategoryOptionItems,
  useInventoryServiceGetProductCategoryOptionItemsKey,
  useInventoryServiceGetProductParentCategoryKey,
} from "api/queries";
import { useProductCategoryFormItem } from "components/common/ProductCategorySelect";
import { useEffect } from "react";

export const CreateCategoryModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}> = ({ isOpen, onClose, queryClient }) => {
  const [form] = Form.useForm();

  const {
    data,
    isLoading,
    isRefetching,
    refetch: refetchCategoryOptions,
  } = useInventoryServiceGetProductCategoryOptionItems(
    {
      filters: "",
      pagination: "",
      sort: "",
    },
    [useInventoryServiceGetProductCategoryOptionItemsKey],
    {
      refetchOnMount: true,
    }
  );

  const [SearchItemComponent, options, validateCreation]: any =
    useProductCategoryFormItem(data, isLoading, isRefetching);

  const { mutate, isPending, error, isError } =
    useInventoryServiceCreateProductCategory({
      onError: (error: any) => {
        console.dir(error);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetProductCategoriesKey],
        });
        onClose();
      },
    });

  const handleSubmit = (values: any) => {
    mutate(
      { requestBody: values },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: [
              useInventoryServiceGetProductCategoriesKey,
              useInventoryServiceGetProductParentCategoryKey,
              useInventoryServiceGetProductCategoryOptionItemsKey,
            ],
            refetchType: "all",
          });
        },
      }
    );
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      title="Create Category"
      open={isOpen}
      onCancel={onClose}
      afterOpenChange={() => {
        refetchCategoryOptions();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleSubmit(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      confirmLoading={isPending}
      maskClosable={false}
      destroyOnClose
      cancelButtonProps={{ disabled: isPending }}
      okButtonProps={{ disabled: isPending }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        // wrapperCol={{ span: 24 }}
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          {isError && (
            <Col xs={24}>
              <Alert
                type="error"
                closable
                message={error?.message}
                description={error?.body?.message || error?.message}
              />
            </Col>
          )}
          <Col xs={12}>
            <Form.Item
              name="productCategoryName"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input the category name name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="parentCategory"
              label="Parent Category"
              rules={[
                { required: false },
                {
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (!validateCreation(options, value, null, true)) {
                      return Promise.reject(
                        new Error("Invalid parent category")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <SearchItemComponent />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="productCategoryDescription" label="Description">
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
