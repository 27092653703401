import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  useCustomerServiceDeleteCustomer,
  useCustomerServiceGetCustomers,
  useCustomerServiceGetCustomersKey,
} from "api/queries";
import qs from "qs";
import { IconCheck, IconX } from "@tabler/icons-react";
import { TableActions } from "components/common";
import CreateCustomerModal from "./CreateCustomerModal";
import { useQueryClient } from "@tanstack/react-query";
import { EditCustomerModal } from "./EditCustomerModal";
import useFilters, { FilterType } from "hooks/UseFilter";

const filtersDef: any[] = [
  {
    key: "customerName",
    label: "Customer Name",
    type: FilterType.Text,
  },
  {
    key: "description",
    label: "Description",
    type: FilterType.Text,
  },
  {
    key: "country",
    label: "Country",
    type: FilterType.Text,
  },
  {
    key: "address",
    label: "Address",
    type: FilterType.Text,
  },
  {
    key: "phone",
    label: "Phone",
    type: FilterType.Text,
  },
  {
    key: "email",
    label: "Email",
    type: FilterType.Text,
  },
  {
    key: "website",
    label: "Website",
    type: FilterType.Text,
  },
];

export default function CustomerListing() {
  const [createNewDialogOpen, setCreateNewDialogOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const queryClient = useQueryClient();

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, refetch, isRefetching } =
    useCustomerServiceGetCustomers(
      {
        pagination: qs.stringify({
          limit,
          offset: (limit * (currentPage - 1)).toString(),
        }),
        filters: qs.stringify(filtersQuery),
        sort: sortQuery,
        searchQuery,
      },
      [useCustomerServiceGetCustomersKey]
    );

  const { mutate: deleteCustomerMutation, isPending: isDeletePending } =
    useCustomerServiceDeleteCustomer({
      onSuccess: () => {
        message.success("Customer deleted successfully");
        refetch();
      },
      onError: (err: any) => {
        message.error(err?.body?.message || err?.message || err);
      },
    });

  const handleCreateModalClose = (success?: boolean) => {
    setCreateNewDialogOpen(false);
  };

  const handleCreateModalOpen = () => {
    setCreateNewDialogOpen(true);
  };

  const handleOpenEditCustomerModal = (customer: any) => {
    setEditDialogOpen(true);
    setSelectedCustomer(customer);
  };

  const handleCloseEditCustomerModal = () => {
    setEditDialogOpen(false);
  };

  const handleDeleteCustomer = async (id: string) => {
    await deleteCustomerMutation({
      idCustomer: id,
    });
    refetch();
  };

  const columns: ColumnsType = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 150,
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 150,
      sorter: true,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 200,
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
      sorter: true,
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      width: 150,
      sorter: true,
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text, record) =>
        record.isActive ? <IconCheck size={16} /> : <IconX size={16} />,
      width: 150,
      sorter: true,
    },
    {
      title: "Actions",
      render: (_r, record) => {
        return (
          <TableActions
            onEdit={() => handleOpenEditCustomerModal(record)}
            onDeletePopConfirm={() =>
              handleDeleteCustomer(record.idCustomer.toString())
            }
            onDeletePopConfirmMessage="Are you sure?"
            onDeletePopConfirmDescription="This customer will be deleted permanently"
            onDeleteLoading={isDeletePending}
          />
        );
      },
      width: 150,
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setCurrentPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    refetch();
  }, [sortQuery, limit, currentPage, filtersQuery, searchQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: "0px" }}>
            Customers
          </Typography.Title>

          <Space>
            {FilterButton}
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={handleCreateModalOpen}
            >
              Add new
            </Button>
          </Space>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24} style={{ marginBottom: 15 }}>
          <Input.Search
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            style={{ flex: 1 }}
          />
        </Col>

        <Col xs={24} style={{ minHeight: 400 }}>
          <Table
            style={{ minHeight: "400px" }}
            scroll={{ x: "max-content" }}
            columns={columns}
            //@ts-ignore
            dataSource={data?.items}
            loading={isLoading || isRefetching}
            rowKey={(item) => item.idCustomer}
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CreateCustomerModal
        isOpen={createNewDialogOpen}
        onClose={handleCreateModalClose}
        queryClient={queryClient}
      />

      <EditCustomerModal
        isOpen={editDialogOpen}
        onClose={handleCloseEditCustomerModal}
        queryClient={queryClient}
        defaultValues={selectedCustomer}
      />
    </motion.div>
  );
}
