import React from "react";
import { Form, Input, Row, Col, Typography, Divider } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const { Title } = Typography;

export interface ClientInformationFormProps {
  form: any;
}

const ClientInformationForm: React.FC<ClientInformationFormProps> = ({ form }) => {
  return (
    <div>
      <Title level={4} style={{ marginBottom: 0 }}>
        Client Information
      </Title>
      <Divider style={{ borderTop: "2px solid #fa8c16" }} />

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: "Please enter the first name" }]}>
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please enter the last name" }]}>
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Primary Phone Number" name="primaryPhone" rules={[{ required: true, message: "Please enter the primary phone number" }]}>
              <PhoneInput
                country={"us"}
                enableSearch
                inputStyle={{ width: "100%" }}
                placeholder="Primary Phone Number"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Secondary Phone Number" name="secondaryPhone">
              <PhoneInput
                country={"us"}
                enableSearch
                inputStyle={{ width: "100%" }}
                placeholder="Secondary Phone Number"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Email" name="email" rules={[{ type: "email", message: "Please enter a valid email" }]}>
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Address" name="address" rules={[{ required: true, message: "Please enter the address" }]}>
              <Input.TextArea placeholder="Address" rows={2} />
            </Form.Item>
          </Col>
        </Row>
    </div>
  );
}

export default ClientInformationForm;