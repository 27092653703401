import { Col, Descriptions, DescriptionsProps } from "antd";
import { useEffect, useState } from "react";

interface IProductDetailsProps {
  data: any;
  company: any;
}

export default function ProductDetails({
  data,
  company,
}: IProductDetailsProps) {
  const [otherDetails, setOtherDetails] = useState<any[]>([]);

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Name",
      children: data?.productName,
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "2",
      label: "SKU",
      children: data?.productSku,
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "3",
      label: "Barcode",
      children: data?.productBarcode,
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "4-category",
      label: "Category",
      children: data?.productCategory?.productCategoryName || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "4-subcategory",
      label: "Sub Category",
      children: data?.productSubcategory?.productCategoryName || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "4-brand",
      label: "Brand",
      children: data?.brand?.brandName || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "4-product-id-code",
      label: "Product ID Code",
      children: data?.productIdCode || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "4-creenCapability",
      label: "Touch Screen Capability",
      children:
        data?.touchScreenCapability?.replace(/_/gi, " ").toLowerCase() || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "4-warrantyInfo",
      label: "Warranty Information",
      children: data?.warrantyInfo?.replace(/_/gi, " ").toLowerCase() || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "4-productGrade",
      label: "Product Grade",
      children: data?.productGrade?.replace(/_/gi, " ").toLowerCase() || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "regulatoryCompliance",
      label: "Regulatory Compliance",
      children:
        data?.regulatoryCompliance?.replace(/_/gi, " ").toLowerCase() || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "5",
      label: "Weight",
      children:
        (data?.productWeight || "0") + " " + data?.productWeightUnit || "-",

      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "6",
      label: "Dimensions (L x W x H)",
      children:
        (data?.productLength || "0") +
          " x " +
          (data?.productWidth || "0") +
          " x " +
          (data?.productHeight || "0") +
          " " +
          data?.productDimensionUnit || "-",
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "8-price",
      label: "Price",
      children: (data?.productPrice || "-") + " " + company.currencySymbol,
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "9-cost",
      label: "Cost",
      children: (data?.productCost || "-") + " " + company.currencySymbol,
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "10-lowstockalert",
      label: "Low Stock Alert",
      children:
        (data?.productLowStockAlert || "-") +
        " " +
        (data?.productStockUnit || ""),
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "11-instock",
      label: "Ideal Stock",
      children:
        (data?.productIdealStock || "-") + " " + (data?.productStockUnit || ""),
      span: { xs: 4, sm: 4, md: 1 },
    },
    {
      key: "7description",
      label: "Description",
      children: data?.productDescription,
      span: { xs: 4, sm: 4, md: 5 },
    },
    {
      key: "12-model-compatiblity",
      label: "Model Compatibility",
      children: data?.models?.map((model: any) => model.modelName).join(", "),
      span: { xs: 4, sm: 4, md: 4 },
    },
    ...otherDetails,
  ];

  useEffect(() => {
    //@ts-ignore
    const abc: any[] = [];
    data?.productVariantValues?.forEach((item: any, idx: number) => {
      abc.push({
        key: "var-" + idx,
        label: item?.productVariantValue?.productVariantType?.name,
        children: item?.productVariantValue?.name,
        span: { xs: 4, sm: 4, md: 1 },
      });
    });
    setOtherDetails(abc);
  }, [data]);

  return (
    <Col xs={23}>
      <div>
        <Descriptions
          title="Product details"
          style={{ width: "100%" }}
          // column={3}
          layout="vertical"
          items={items}
          // bordered={true}
          column={4}
        />
      </div>
    </Col>
  );
}
