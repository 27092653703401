import {
  Button,
  Col,
  Divider,
  Flex,
  notification,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { useState } from "react";
import CreateProductVariantModal from "./CreateProductVariantModal";
import { TableActions } from "components/common";
import EditProductVariantModal from "./EditProductVariantModal";
import { useQueryClient } from "@tanstack/react-query";
import {
  useInventoryServiceDeleteProductVariant,
  useInventoryServiceGetProductKey,
  useInventoryServiceGetProductsKey,
} from "api/queries";
import { useNavigate } from "react-router-dom";

interface IProductVariantsProps {
  data: any;
  refetch: () => void;
}

export default function ProductVariants({
  data,
  refetch,
}: IProductVariantsProps) {
  const [createProductVariantModalOpen, setCreateProductVariantModalOpen] =
    useState(false);
  const [editProductVariantModalOpen, setEditProductVariantModalOpen] =
    useState(false);
  const [selectedProductVariant, setSelectedProductVariant] =
    useState<any>(null);

  const nav = useNavigate();

  const { mutate: deletProduct, isPending: isDeleting } =
    useInventoryServiceDeleteProductVariant({
      onError: (error: any) => {
        notification.error({
          message: "Error",
          description:
            error?.body?.message ||
            error?.response?.data?.message ||
            error?.message,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetProductKey],
        });
        refetch();
      },
    });

  const queryClient = useQueryClient();

  const handleOpenCreateProductVariantModal = () => {
    setCreateProductVariantModalOpen(true);
  };

  const handleCloseCreateProductVariantModal = () => {
    setCreateProductVariantModalOpen(false);
  };

  const handleOpenEditProductVariantModal = (productVariant: any) => {
    setEditProductVariantModalOpen(true);
    setSelectedProductVariant(productVariant);
  };

  const handleCloseEditProductVariantModal = () => {
    setEditProductVariantModalOpen(false);
    setSelectedProductVariant(null);
  };

  return (
    <Col xs={24}>
      <Row>
        <Col xs={24}>
          <Flex justify="space-between" align="center">
            <Typography.Title>Product Variants</Typography.Title>
            <Button
              type="primary"
              onClick={handleOpenCreateProductVariantModal}
            >
              Create Product Variant
            </Button>
          </Flex>
        </Col>
        <CreateProductVariantModal
          isOpen={createProductVariantModalOpen}
          onClose={handleCloseCreateProductVariantModal}
          productData={data}
          queryClient={queryClient}
          refetch={refetch}
        />

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24}>
          <Table
            key={"product-variant-list"}
            rowKey={(item) => item.idProductVariant}
            dataSource={data.productVariants}
            columns={[
              {
                dataIndex: "productName",
                key: "productName",
                title: "Name",
                width: "auto",
              },
              {
                dataIndex: "isActive",
                key: "isActive",
                width: "auto",
                title: "Active",
                render: (isActive: boolean) => (
                  <Typography.Text>{isActive ? "Yes" : "No"}</Typography.Text>
                ),
              },
              {
                dataIndex: "productSku",
                key: "productSku",
                title: "SKU",
                width: "auto",
              },
              {
                dataIndex: "productPrice",
                key: "productPrice",
                title: "Price",
                width: "auto",
              },
              {
                dataIndex: "productVariantValues",
                key: "productVariantValues",
                title: "Attributes",
                width: "auto",
                render: (productVariantValues: any) => (
                  <Space>
                    {productVariantValues?.map((pvv: any) => {
                      return (
                        <Tag
                          key={pvv?.productVariantValue?.idProductVariantValue}
                        >
                          {pvv?.productVariantValue?.name}
                        </Tag>
                      );
                    })}
                  </Space>
                ),
              },
              {
                dataIndex: "IdProduct",
                key: "IdProduct",
                title: "Actions",
                render: (_value, record) => (
                  <TableActions
                    onView={() =>
                      nav(`/inventory/products/${record.idProduct}`)
                    }
                    onEdit={() => handleOpenEditProductVariantModal(record)}
                    onDeletePopConfirm={() =>
                      deletProduct({
                        id: record.idProduct,
                      })
                    }
                    onDeleteLoading={isDeleting}
                    onDeletePopConfirmMessage="Are you sure you want to delete this product variant?"
                    onDeletePopConfirmDescription="This will permanently delete the product variant and all its data."
                  />
                ),
              },
            ]}
            scroll={{ x: true }}
            pagination={false}
          />
        </Col>
      </Row>

      <EditProductVariantModal
        isOpen={editProductVariantModalOpen}
        onClose={handleCloseEditProductVariantModal}
        defaultValues={selectedProductVariant}
        queryClient={queryClient}
        refetch={refetch}
      />
    </Col>
  );
}
