import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Button,
  Col,
  Divider,
  Row,
  Space,
  Table,
  Typography,
  Card,
  Statistic,
  Input,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CreateReparation from "./components/CreateReparation";
import {
  useReparationServiceGetReparationCases,
  useReparationServiceGetReparationCasesKey,
} from "api/queries";
import { ColumnType } from "antd/es/table";
import { IReparationCaseListItem } from "api/requests";
import CaseStatusTag from "./components/CaseStatusTag";
import { useQueryClient } from "@tanstack/react-query";
import { TableActions } from "components/common";
import { useNavigate } from "react-router-dom";

export default function ReparationList() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [createReparationDrawerOpen, setCreateReparationDrawerOpen] =
    useState(false);

  const queryClient = useQueryClient();
  const nav = useNavigate();


  const { isLoading, data, refetch } = useReparationServiceGetReparationCases(
    {
      search,
      limit,
      offset: (page - 1) * limit,
      sort: sortQuery,
      status,
    },
    [useReparationServiceGetReparationCasesKey]
  );

  const columns: ColumnType<IReparationCaseListItem>[] = [
    {
      key: "idReparationCase",
      dataIndex: "idReparationCase",
      title: "Case ID",
      render: (value: number) => "REP" + value.toString().padStart(5, "0"),
      sorter: true,
    },
    {
      key: "customerName",
      dataIndex: "firstName",
      title: "Customer",
      render: (_value: string, r) => r.firstName + " " + r.lastName,
      sorter: true,
    },
    {
      key: "primaryPhone",
      dataIndex: "primaryPhone",
      title: "Phone",
      sorter: true,
    },
    {
      key: "model",
      dataIndex: "model",
      title: "Device",
      render: (_value: string, r) => r.brand?.brandName + " " + r.model,
      sorter: false,
    },
    {
      key: "reportedCaseIssue",
      dataIndex: "reportedCaseIssue",
      title: "Issue",
      sorter: true,
    },
    {
      key: "reparationCaseStatus",
      dataIndex: "reparationCaseStatus",
      title: "Status",
      render: (v) => <CaseStatusTag status={v} />,
      sorter: true,
    },
    {
      key: "createdDate",
      dataIndex: "createdDate",
      title: "Created Date",
      render: (value: string) => dayjs(value).format("DD MMM YYYY"),
      sorter: true,
    },
    {
      key: "actions",
      title: "Actions",
      render: (value: any, record: any) => <TableActions onView={() => handleView(record.idReparationCase)} />,
    },
  ];

  const handleView = (reparationCaseId: string) => {
    nav(`/sales/reparations/${reparationCaseId}`);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    console.log("Pagination:", pagination);
    console.log("Sorter:", sorter);
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    queryClient.refetchQueries({
      queryKey: [useReparationServiceGetReparationCasesKey],
    });
  }, [sortQuery, limit, page]);

  const handleCreateReparation = () => {
    setCreateReparationDrawerOpen(true);
  };

  const handleCloseCreateReparation = () => {
    setCreateReparationDrawerOpen(false);
  };

  useEffect(() => {
    queryClient.resetQueries({
      queryKey: [useReparationServiceGetReparationCasesKey],
    });
  }, [sortQuery, search, page, limit]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Row>
        <Col xs={24}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card>
                <Statistic
                  title="New Cases Today"
                  value={data?.totalNewCasesToday || 0}
                  loading={isLoading}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card>
                <Statistic
                  title="Pending Cases"
                  value={data?.totalPendingCasesToday || 0}
                  loading={isLoading}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card>
                <Statistic
                  title="Completed Today"
                  value={data?.totalCompletedCasesToday || 0}
                  loading={isLoading}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Typography.Title>Reparations</Typography.Title>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Space>
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={handleCreateReparation}
            >
              Add New
            </Button>
          </Space>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Input.Search
            placeholder="Search by reparation case number"
            style={{ width: "100%" }}
            onSearch={setSearch}
            loading={isLoading}
            allowClear={true}
          />
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={data?.items}
            loading={isLoading}
            pagination={{
              total: data?.total || 0,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
            rowKey={(item) => item.idReparationCase}
          />
        </Col>

        <CreateReparation
          isOpen={createReparationDrawerOpen}
          onClose={handleCloseCreateReparation}
        />
      </Row>
    </motion.div>
  );
}
