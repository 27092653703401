import { Routes, Route, useLocation } from "react-router-dom";
import LoginForm from "../pages/Auth/LoginPage"; // Adjust the import path to where your LoginForm is located
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "pages/Dashboard";
import Layout from "components/layout/Layout";
import ChartOfAccounts from "pages/Accounting/Accounts";
import UserListing from "pages/Users";
import VendorListing from "pages/Purchases/vendors";
import TransactionListing from "pages/Accounting/Transactions";
import CustomerListing from "pages/Sales/Customers";
import ProductListing from "pages/Inventory/Products";
import ServiceListingPage from "pages/Inventory/Services";
import NotFound from "pages/NotFound";
import { ServiceCategoryListing } from "pages/Inventory/ServiceCategory";
import LocationListingPage from "pages/Inventory/Location";
import ProductCategoryListingPage from "pages/Inventory/ProductCategory";
import BrandListingPage from "pages/Inventory/Brand";
import SettingsPage from "pages/Settings";
import TaxListingPage from "pages/Accounting/Tax";
import CurrencyListingPage from "pages/Accounting/Currency";
import PurchaseOrderListing from "pages/Purchases/PurchaseOrders";
import ProductView from "pages/Inventory/Products/ProductView";
import PurchaseOrderDetails from "pages/Purchases/PurchaseOrders/PurchaseOrderDetails";
import { ExpenseCategoryListingPage } from "pages/Accounting/ExpenseCategories";
import ExpenseListingPage from "pages/Accounting/Expenses";
import BanksListingPage from "pages/Accounting/Banks";
import InvoiceListing from "pages/Sales/Invoice";
import RolesSettingsPage from "pages/Settings/Roles";
import EditRole from "pages/Settings/Roles/EditRole";
import InventoryTransactionListing from "pages/Inventory/Inventory";
import VendorDetailsPage from "pages/Purchases/vendors/VendorDetailsPage";
import ProductVariantsSettingsPage from "pages/Settings/Variants";
import ProductModelSettingsPage from "pages/Settings/ProductModel";
import CompanyDetails from "pages/Settings/CompanyDetails";
import BackupComponent from "pages/Settings/BackupComponent";
import ReportsPage from "pages/Reports";
import StockListing from "pages/Inventory/Stock";
import InvoiceDetails from "pages/Sales/Invoice/InvoiceDetails";
import ReparationList from "pages/Sales/Reparations";
import ReparationCaseDetails from "pages/Sales/Reparations/components/ReparationCaseDetails";

const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      <Route key="route-login" path="/login" element={<LoginForm />} />
      <Route key="route-root" path="/" element={<ProtectedRoute />}>
        <Route
          key="route-layout"
          element={<Layout />}
          children={[
            <Route key="route-dashboard" path="/" element={<Dashboard />} />,
            <Route
              key="route-chart-of-accounts-routes"
              path="/accounting"
              children={[
                <Route
                  key="route-chart-of-accounts"
                  path="/accounting/chart-of-accounts"
                  element={<ChartOfAccounts />}
                />,
                <Route
                  key="route-transaction-listing"
                  path="/accounting/transaction-listing"
                  element={<TransactionListing />}
                />,
                <Route
                  key="route-bank-accounts"
                  path="/accounting/bank-accounts"
                  element={<BanksListingPage />}
                />,
                <Route
                  key="route-expense-categories-listing"
                  path="expense-categories"
                  element={<ExpenseCategoryListingPage />}
                />,
                <Route
                  key="route-expenses-listing"
                  path="expenses"
                  element={<ExpenseListingPage />}
                />,
                <Route
                  key="route-tax-listing"
                  path="/accounting/tax"
                  element={<TaxListingPage />}
                />,

                <Route
                  key="route-currencies"
                  path="/accounting/currencies"
                  element={<CurrencyListingPage />}
                />,
              ]}
            />,
            <Route
              key="route-users"
              path="/users"
              children={[
                <Route path="" key={"user-list"} element={<UserListing />} />,
              ]}
            />,
            <Route
              key="route-customers"
              path="/sales"
              children={[
                <Route
                  path="customers"
                  key={"customer-list"}
                  element={<CustomerListing />}
                />,
                <Route
                  path="reparations"
                  key={"reparation-list"}
                  element={<ReparationList />}
                />,
                <Route path="reparations/:id" element={<ReparationCaseDetails />} />,
                <Route
                  path="invoices"
                  key={"invoice-list"}
                  children={[
                    <Route
                      key="route-invoice-list"
                      path=""
                      element={<InvoiceListing />}
                    />,
                    <Route
                      key="route-invoice-details"
                      path=":id"
                      element={<InvoiceDetails />}
                    />,
                  ]}
                />,
              ]}
            />,
            <Route
              key="route-purchases"
              path="/purchases"
              children={[
                <Route
                  path="/purchases/vendors"
                  key={"vendor-list"}
                  element={<VendorListing />}
                />,
                <Route
                  path="/purchases/vendors/:id"
                  element={<VendorDetailsPage />}
                />,
                <Route
                  path="/purchases/purchase-orders"
                  key={"purchases-order-list"}
                  children={[
                    <Route
                      key="route-purchase-orders"
                      path=""
                      element={<PurchaseOrderListing />}
                    />,
                    <Route
                      key="route-purchase-orders-create"
                      path=":id"
                      element={<PurchaseOrderDetails />}
                    />,
                  ]}
                />,
              ]}
            />,
            <Route
              key="route-inventory"
              path="/inventory"
              children={[
                <Route
                  key="route-transactions"
                  path="/inventory/transactions"
                  element={<InventoryTransactionListing />}
                />,
                <Route
                  key="route-products"
                  path="/inventory/products"
                  element={<ProductListing />}
                />,
                <Route
                  key="route-product-view"
                  path="/inventory/products/:id"
                  element={<ProductView />}
                />,
                <Route
                  key="route-product-category"
                  path="/inventory/product-category"
                  element={<ProductCategoryListingPage />}
                />,
                <Route
                  key="route-brands"
                  path="/inventory/brands"
                  element={<BrandListingPage />}
                />,
                <Route
                  key="route-services"
                  path="/inventory/services"
                  element={<ServiceListingPage />}
                />,
                <Route
                  key="route-service-category"
                  path="/inventory/service-category"
                  element={<ServiceCategoryListing />}
                />,
                <Route
                  key="route-location"
                  path="/inventory/location"
                  element={<LocationListingPage />}
                />,
                <Route
                  key={"route-stock"}
                  path="/inventory/stock"
                  element={<StockListing />}
                />,
              ]}
            />,
            <Route
              key="route-settings"
              path="/settings"
              element={<SettingsPage />}
              children={[
                <Route
                  key="route-company-details"
                  path="/settings/company-details"
                  element={<CompanyDetails />}
                />,
                <Route
                  key="route-settings-roles"
                  path="/settings/roles"
                  children={[
                    <Route
                      key="route-settings-roles-routes"
                      path="/settings/roles"
                      element={<RolesSettingsPage />}
                    />,
                    <Route
                      key="route-roles-edit"
                      path="/settings/roles/:id"
                      element={<EditRole />}
                    />,
                  ]}
                />,
                <Route
                  key={"route-settings-product-variants"}
                  path="/settings/product-variants"
                  element={<ProductVariantsSettingsPage />}
                />,
                <Route
                  key={"route-settings-product-models"}
                  path="/settings/product-models"
                  element={<ProductModelSettingsPage />}
                />,
                <Route
                  key={"route-settings-backup"}
                  path="/settings/backup"
                  element={<BackupComponent />}
                />,
              ]}
            />,
            <Route
              key="route-reports"
              path="/reports"
              element={<ReportsPage />}
            />,
          ]}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
