import { Select, SelectProps } from "antd";
import {
  useInventoryServiceGetProductCategoriesKey,
  useInventoryServiceGetProductCategoryOptionItems,
  useInventoryServiceGetProductCategoryOptionItemsKey,
  useInventoryServiceGetProductParentCategoryKey,
} from "api/queries";
import { IGetProductCategoryOptionItemsResponse } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface IProductCategorySelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: IGetProductCategoryOptionItemsResponse[];
  selectProps?: Partial<SelectProps>;
}

function transformCategories(categories: any[]): any[] {
  const result: any[] = [];
  const seen = new Set<number>(); // To keep track of processed category IDs

  function getIndentedLabel(label: string, depth: number): string {
    // Returns label with dashes according to the depth
    return `${"-".repeat(depth)} ${label}`;
  }

  function processCategory(
    category: any,
    depth: number,
    parentValue: number | null
  ) {
    // Check if this category ID has been processed before
    if (!seen.has(category.idProductCategory)) {
      seen.add(category.idProductCategory);

      result.push({
        label: getIndentedLabel(category.productCategoryName, depth),
        value: category.idProductCategory,
        depth,
        parentValue,
      });

      if (category.children) {
        category.children.forEach((child: any) =>
          processCategory(child, depth + 1, category.idProductCategory)
        );
      }
    }
  }

  categories.forEach((category) => processCategory(category, 0, null));

  return result;
}

function rebuildObj(
  obj: any[],
  currentItemId: number | null = null,
  selectedParentId: number | null = null,
  isNewItem: boolean = false
) {
  const currentItem = obj.find((i) => i.value === currentItemId);
  const parentOfCurrentItem = obj.find(
    (i) => i.value === currentItem?.parentValue
  );
  const parentOfParentOfCurrentItem = obj.find(
    (i) => i.value === parentOfCurrentItem?.parentValue
  );
  const childOfCurrentItem = obj.find(
    (i) => i.parentValue === currentItem?.value
  );
  const childOfChildOfCurrentItem = obj.find(
    (i) => i.parentValue === childOfCurrentItem?.value
  );

  const selectedItem = obj.find((i) => i.value === selectedParentId);
  const selectedParentOfCurrentItem = obj.find(
    (i) => i.value === selectedItem?.parentValue
  );
  const selectedParentOfParentOfCurrentItem = obj.find(
    (i) => i.value === selectedParentOfCurrentItem?.parentValue
  );

  if (isNewItem) {
    //cannot choose where has parent of parent
    if (parentOfParentOfCurrentItem) {
      return false;
    }
  }

  if (parentOfParentOfCurrentItem) {
    return false;
  }

  if (currentItemId && selectedParentId && currentItemId === selectedParentId) {
    return false;
  }

  if (childOfChildOfCurrentItem && parentOfCurrentItem) {
    return false;
  }

  if (selectedParentOfParentOfCurrentItem) {
    return false;
  }

  return true;
}

const ProductCategorySelectWithForm: React.FC<IProductCategorySelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetProductCategoryOptionItems(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [
        useInventoryServiceGetProductCategoryOptionItemsKey,
        useInventoryServiceGetProductCategoriesKey,
      ]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            productCategoryName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a category"
      notFoundContent={null}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={transformCategories(data ?? []) ?? []}
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const ProductCategorySelectNoForm: React.FC<IProductCategorySelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetProductCategoryOptionItems(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [
        useInventoryServiceGetProductCategoryOptionItemsKey,
        useInventoryServiceGetProductCategoriesKey,
      ]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            productCategoryName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a category"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={transformCategories(data ?? []) ?? []}
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

export const useProductCategoryFormItem = (data: any, isLoading: any, isRefetching: any) => {
  

  const SearchItemComponent = ({ value, onChange, ...rest }: any) => (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a category"
      notFoundContent={null}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={transformCategories(data ?? []) ?? []}
      {...rest.selectProps}
    />
  );
  return [SearchItemComponent, transformCategories(data ?? []), rebuildObj];
};

export default function ProductCategorySelect(
  props: IProductCategorySelectProps
) {
  if (props.isForm) {
    return <ProductCategorySelectWithForm {...props} />;
  }
  return <ProductCategorySelectNoForm {...props} />;
}
